import {graphql} from 'gatsby'
import {useI18next} from 'gatsby-plugin-react-i18next'
import React from 'react'
import styled from 'styled-components'
import {SectionDescription, SectionTitle, TitleText} from '../appearance/styled'

const Accessibility = () => {
    const {language} = useI18next()
    return (
        <Container>
            <TitleText lang={language}>הצהרת נגישות</TitleText>
            <Content>
                אתר זה מייחס חשיבות רבה להנגשת השימוש בו לכלל האוכלוסייה ועושה כל שביכולתו להבטיח
                שאנשים עם מוגבלות יוכלו להפיק את המיטב מהתכנים המוצגים בו. אתר זה משתמש בתוסף
                <Link href="https://www.nagish.li/he/" target="_blank">
                    נגיש לי
                </Link>
                לשם מטרה חשובה זו, והשימוש בתוסף כפוף לתנאי
                <Link href="https://www.nagish.li/he/license.html" target="_blank">
                    הרישיון
                </Link>
                המצורפים אליו.
                <br />
                <br />
                <br />
                <SectionTitle lang={language}>איך להשתמש בתוסף הנגישות</SectionTitle>
                לאחר הלחיצה על כפתור הנגישות ייפתחו בפניכם תפריטי תוסף הנגישות, באמצעותם תוכלו:
                <ul>
                    <li>לשנות את גודל הטקסט (ניתן להשתמש גם בקיצורי המקשים SHIFT+A / SHIFT+S) </li>
                    <li>
                        לשנות את צבעי האתר (ניתן גם להשתמש בקיצורי המקשים SHIFT+Q / SHIFT+W /
                        SHIFT+E)
                    </li>
                    <li>לשנות את תצוגת האתר</li>
                    <li>
                        להבליט קישורים וכותרות באתר (ניתן להשתמש גם בקיצורי המקשים SHIFT+D /SHIFT+F)
                    </li>
                    <li>
                        לנווט במהירות בין אזורים בכל עמוד (ניתן להשתמש גם בקיצורי המקשים ALT+V /
                        ALT+B)
                    </li>
                    <li> להגדיל ולהקטין את תצוגת האתר(זום)</li>
                    <li>לשנות את גופן האתר</li>
                    <li>לעצור הנפשות (אנימציות) באתר</li>
                    <li>להאזין לטקסט חלופי עבור תמונות באמצעות תוכנת הקראה</li>
                    <li>להאזין למספרי טלפון באמצעות הקראה קולית</li>
                    <li>לשנות את סמן העכבר</li>
                    <li>
                        לנווט בין אפשרויות התוסף באמצעות המקש tab (וגם SHIFT+TAB) והפעלתן באמצעות
                        המקש Enter
                    </li>
                    <br />
                    <br />
                </ul>
                למרות המאמצים שהושקעו בפיתוח תוסף זה, ייתכנו מצבים שבהם לא תהיה לו אפשרות להנגיש חלק
                מתכני האתר, כגון תכנים המוצעים דרך גורמי צד שלישי (כדוגמת YouTube, תכנים מוטמעים
                מאתרים אחרים וכדומה). חשוב לציין שנגיש לי נמצא בפיתוח מתמיד על מנת להשתפר ולעמוד
                בתקני הנגישות העדכניים ביותר.
            </Content>
        </Container>
    )
}

export default Accessibility

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

const Container = styled.div``

const Content = styled(SectionDescription)`
    display: inline;
`

const Link = styled.a`
    color: grey;
    display: inline-block;
    margin-inline: 5px;
`
